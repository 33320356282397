<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400" @click="toggleNewImage(1)">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400" @click="toggleNewImage(2)">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" alt="" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400" @click="toggleNewImage(3)">
      <div class="img3 max-w-1400">
        <img
          :src="showNewImage3 ? AnswerImg3 : importedImg3"
          :alt="altText"
          class="imgItem3"
        />
      </div>
    </div>
    <div class="imgBox4 min-w-1400" @click="toggleNewImage(4)">
      <div class="img4 max-w-1400">
        <img
          :src="showNewImage4 ? AnswerImg4 : importedImg4"
          :alt="altText"
          class="imgItem4"
        />
      </div>
    </div>
    <div class="imgBox5 min-w-1400" @click="toggleNewImage(5)">
      <div class="img5 max-w-1400">
        <img
          :src="showNewImage5 ? AnswerImg5 : importedImg5"
          :alt="altText"
          class="imgItem5"
        />
      </div>
    </div>
    <div class="imgBox6 min-w-1400" @click="toggleNewImage(6)">
      <div class="img6 max-w-1400">
        <img
          :src="showNewImage6 ? AnswerImg6 : importedImg6"
          :alt="altText"
          class="imgItem6"
        />
      </div>
    </div>
    <div class="imgBox6 min-w-1400" @click="toggleNewImage(7)">
      <div class="img7 max-w-1400">
        <img
          :src="showNewImage7 ? AnswerImg7 : importedImg7"
          :alt="altText"
          class="imgItem7"
        />
      </div>
    </div>

    <div class="imgBox8 min-w-1400" @click="toggleNewImage(9)">
      <div class="img9 max-w-1400">
        <img
          :src="showNewImage9 ? AnswerImg8 : importedImg9"
          :alt="altText"
          class="imgItem9"
        />
      </div>
    </div>
    <div class="imgBox9 min-w-1400" @click="toggleNewImage(10)">
      <div class="img10 max-w-1400">
        <img
          :src="showNewImage10 ? AnswerImg9 : importedImg10"
          :alt="altText"
          class="imgItem10"
        />
      </div>
    </div>
    <div class="imgBox11 min-w-1400">
      <div class="img11 max-w-1400">
        <!-- 使用 Flex 布局，水平排列三个大标题 -->
        <div class="titlesContainer">
          <!-- 第一个大标题 -->
          <div class="titleContainer1">
            <h2>เกี่ยวกับเรา</h2>
            <!-- 第一个大标题下的小标题 -->
            <div class="subtitles">
              <ul>
                <router-link to="/" tag="li" class="triangle">
                  <a>พื้นหลัง</a>
                </router-link>
                <router-link to="/questions" tag="li" class="triangle">
                  <a>คำถามที่พบบ่อย</a>
                </router-link>
              </ul>
            </div>
          </div>

          <!-- 第二个大标题 -->
          <div class="titleContainer2">
            <h2>นโยบาย</h2>
            <!-- 第二个大标题下的小标题 -->
            <div class="subtitles">
              <ul class="ul2">
                <li tag="li" class="triangle">
                  <a
                    href="https://mbawt.microfinmobile.com/help/privacy_agreement"
                  >
                    ข้อตกลงความเป็นส่วนตัว
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <!-- 第三个大标题 -->
          <div class="titleContainer3">
            <h2>ติดต่อเรา</h2>
            <!-- 第三个大标题下的小标题 -->
            <div class="subtitles">
              <ul>
                <li>Micro Fin Company Limited</li>
                <li class="Li2">
                  ที่อยู่: 863/3 Phet Kasem Road 73000 MUEANG NAKHON PATHOM
                </li>
                <li>โทรศัพท์: 94-823-3850</li>
                <li>อีเมล: service@microfinmobile.com</li>
                <li>เว็บไซต์: https://microfinmobile.com</li>
                <li>
                  <img :src="importedImg11" :alt="altText" class="imgItem11" />
                </li>
                <li class="Li3">แอปจะเปิดตัวเร็วๆ นี้ โปรดคอยติดตาม</li>

                <li>
                  <img :src="importedImg12" :alt="altText" class="imgItem12" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/microfin2/components/img/2_1.png";
import importedImg2 from "@/page/microfin2/components/img/2_2.png";
import importedImg3 from "@/page/microfin2/components/img/2_3.png";
import importedImg4 from "@/page/microfin2/components/img/2_4.png";
import importedImg5 from "@/page/microfin2/components/img/2_5.png";
import importedImg6 from "@/page/microfin2/components/img/2_6.png";
import importedImg7 from "@/page/microfin2/components/img/2_8.png";
import importedImg8 from "@/page/microfin2/components/img/2_8.png";
import importedImg9 from "@/page/microfin2/components/img/2_9.png";
import importedImg10 from "@/page/microfin2/components/img/2_10.png";
import importedImg11 from "@/page/microfin2/components/img/button.png";
import importedImg12 from "@/page/microfin2/components/img/111.png";
import AnswerImg3 from "@/page/microfin2/components/img/2_3_3.png";
import AnswerImg4 from "@/page/microfin2/components/img/2_4_4.png";
import AnswerImg5 from "@/page/microfin2/components/img/2_5_5.png";
import AnswerImg6 from "@/page/microfin2/components/img/2_6_6.png";
import AnswerImg7 from "@/page/microfin2/components/img/2_7_7.png";
import AnswerImg8 from "@/page/microfin2/components/img/2_8_8.png";
import AnswerImg9 from "@/page/microfin2/components/img/2_9_9.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      importedImg7: importedImg7,
      importedImg8: importedImg8,
      importedImg9: importedImg9,
      importedImg10: importedImg10,
      importedImg11: importedImg11,
      importedImg12: importedImg12,
      AnswerImg3: AnswerImg3,
      AnswerImg4: AnswerImg4,
      AnswerImg5: AnswerImg5,
      AnswerImg6: AnswerImg6,
      AnswerImg7: AnswerImg7,
      AnswerImg8: AnswerImg8,
      AnswerImg9: AnswerImg9,
      altText: "图片",
      showNewImage2: false,
      showNewImage3: false,
      showNewImage4: false,
      showNewImage5: false,
      showNewImage6: false,
      showNewImage7: false,
      showNewImage8: false,
      showNewImage9: false,
      showNewImage10: false,
      activeImage: 0,
    };
  },
  created() {
    this.gotoTop();
  },

  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    toggleNewImage(imageIndex) {
      //  console.log("点击了");
      //   this.$set(this.showNewImage, imageIndex, !this.showNewImage[imageIndex]);
      //   this.activeImage = imageIndex;
      console.log(this.showNewImage3);
      if (imageIndex == 2) {
        this.showNewImage2 = !this.showNewImage2;
      }
      if (imageIndex == 3) {
        this.showNewImage3 = !this.showNewImage3;
      }
      if (imageIndex == 4) {
        this.showNewImage4 = !this.showNewImage4;
      }
      if (imageIndex == 5) {
        this.showNewImage5 = !this.showNewImage5;
      }
      if (imageIndex == 6) {
        this.showNewImage6 = !this.showNewImage6;
      }
      if (imageIndex == 7) {
        this.showNewImage7 = !this.showNewImage7;
      }
      if (imageIndex == 8) {
        this.showNewImage8 = !this.showNewImage8;
      }
      if (imageIndex == 9) {
        this.showNewImage9 = !this.showNewImage9;
      }
      if (imageIndex == 10) {
        this.showNewImage10 = !this.showNewImage10;
      }
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.imgBox1,
.imgBox11 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}
.imgBox1 {
  margin-top: 80px;
}
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7,
.imgBox8,
.imgBox9,
.imgBox10 {
  display: flex;
  justify-content: center;
  width: 100%;
}
.imgBox11 {
  display: flex;
}
img {
  display: block;
}
.img1 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  justify-content: space-between;
}
.img2,
.img3,
.img4,
.img5,
.img6,
.img7,
.img8,
.img9,
.img10 {
  margin-left: 45px;
  margin-right: 45px;
}
.img11 {
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7,
.imgItem8,
.imgItem9,
.imgItem10 {
  max-width: 100%;
}
.imgItem4,
.imgItem5,
.imgItem6 {
  margin-bottom: 20px;
}

.imgItem10 {
  margin-bottom: 100px;
}
.imgBox11 {
  display: flex;
  justify-content: center;
  background-color: #ca4a3d;
  height: 400px; /* 固定高度 */
  min-width: 1400px;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.titlesContainer {
  display: flex;
  width: 100%;
}

.titleContainer1 {
  width: 200px;
  position: absolute;
  margin-top: 30px;
  margin-left: 200px;
}
.titleContainer2 {
  padding-left: 450px;
  margin-top: 30px;
}
.titleContainer3 {
  margin-left: 120px;
  margin-top: 30px;
}
.subtitles {
  display: flex;
  flex-direction: column;
}

li {
  color: #ffffff;
  margin-top: 10px;
}
h2 {
  color: #ffffff;
}
.Li2 {
  width: 900px;
}
.ul2 li {
  width: 220px;
}
.subtitles ul li.triangle::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px; /* 调整这里以改变三角形的大小 */
  border-color: transparent transparent transparent #ffffff; /* 设置三角形的颜色 */
  margin-right: 20px; /* 图标与文字之间的间距 */
}

.triangle a {
  color: #ffffff;
  text-decoration: none; /* 添加这一行，去掉下划线 */
  cursor: pointer; /* 添加这一行，显示手型光标 */
}

li {
  font-size: 18px;
}
.Li3 {
  color: #c1c0c0;
  font-size: 14px;
}
</style>