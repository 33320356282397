<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem2" />
      </div>
    </div>

    <div class="imgBox11 min-w-1400">
      <div class="img11 max-w-1400">
        <!-- 使用 Flex 布局，水平排列三个大标题 -->
        <div class="titlesContainer">
          <!-- 第一个大标题 -->
          <div class="titleContainer1">
            <h2>เกี่ยวกับเรา</h2>
            <!-- 第一个大标题下的小标题 -->
            <div class="subtitles">
              <ul>
                <router-link to="/" tag="li" class="triangle">
                  <a>พื้นหลัง</a>
                </router-link>
                <router-link to="/questions" tag="li" class="triangle">
                  <a>คำถามที่พบบ่อย</a>
                </router-link>
              </ul>
            </div>
          </div>

          <!-- 第二个大标题 -->
          <div class="titleContainer2">
            <h2>นโยบาย</h2>
            <!-- 第二个大标题下的小标题 -->
            <div class="subtitles">
              <ul class="ul2">
                <router-link to="/protocol" tag="li" class="triangle">
                  <a>ข้อตกลงความเป็นส่วนตัว</a>
                </router-link>
              </ul>
            </div>
          </div>

          <!-- 第三个大标题 -->
          <div class="titleContainer3">
            <h2>ติดต่อเรา</h2>
            <!-- 第三个大标题下的小标题 -->
            <div class="subtitles">
              <ul>
                <li>Micro Fin Company Limited</li>
                <li class="Li2">
                  ที่อยู่: 863/3 Phet Kasem Road 73000 MUEANG NAKHON PATHOM
                </li>
                <li>โทรศัพท์: 94-823-3850</li>
                <li>อีเมล: service@microfinmobile.com</li>
                <li>เว็บไซต์: https://microfinmobile.com</li>
                <li>
                  <img :src="importedImg11" :alt="altText" class="imgItem11" />
                </li>
                <li class="Li3">แอปจะเปิดตัวเร็วๆ นี้ โปรดคอยติดตาม</li>

                <li>
                  <img :src="importedImg12" :alt="altText" class="imgItem12" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/microfin2/components/img/4_2.png";

import importedImg11 from "@/page/microfin2/components/img/button.png";
import importedImg12 from "@/page/microfin2/components/img/111.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg11: importedImg11,
      importedImg12: importedImg12,
      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },

  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}
.imgBox11 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox2 {
  display: flex;
  justify-content: center;
  width: 100%;
}
.imgBox2{
  margin-top: 80px;
}
img {
  display: block;
}
.img11 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.img2 {
  margin-left: 45px;
  margin-right: 45px;
}
.img11 {
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem2 {
  max-width: 100%;
  margin-bottom: 100px;
  margin-top: 30px;
}
.imgBox11 {
  display: flex;
  justify-content: center;
  background-color: #CA4A3D;
  height: 400px; /* 固定高度 */
  min-width: 1400px;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.titlesContainer {
  display: flex;
  width: 100%;
}

.titleContainer1 {
  width: 200px;
  position: absolute;
  margin-top: 30px;
  margin-left: 200px;
}
.titleContainer2 {
padding-left: 450px;
  margin-top: 30px;
}
.titleContainer3 {
  margin-left: 120px;
  margin-top: 30px;
}
.subtitles {
  display: flex;
  flex-direction: column;
}

li {
  color: #ffffff;
  margin-top: 10px;
}
h2 {
  color: #ffffff;
}
.Li2 {
  width: 900px;
}
.ul2 li{
  width: 220px;
}
.subtitles ul li.triangle::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px; /* 调整这里以改变三角形的大小 */
  border-color: transparent transparent transparent #ffffff; /* 设置三角形的颜色 */
  margin-right: 20px; /* 图标与文字之间的间距 */
}

.triangle a {
  color: #ffffff;
  text-decoration: none; /* 添加这一行，去掉下划线 */
  cursor: pointer; /* 添加这一行，显示手型光标 */
}

li {
  font-size: 18px;
}
.Li3 {
  color: #c1c0c0;
  font-size: 14px;
}
</style>
